import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/gail-ann-turner.jpeg";
  const fullName = "Gail Ann Turner";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const birthDate = "September 5, 1942";
  const deathDate = "November 3, 2020";
  const memorialDate = "Saturday November 21, 2020";
  const memorialTime = "1pm EST";
  const inviteText = "";
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl = "https://www.youtube.com/embed/90VEZG5XkK4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      recordingEmbedUrl={recordingEmbedUrl}
      customColumnSpacing="1fr 2fr"
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    />
  );
};

export default ThisPage;
